import { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Rewards.css';

function SECTION_REWARDS() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <section className='section-rewards-wrapper'>
      <div className='section-rewards-wrapper-container'>
        <h2>{translations.landingPage_sectionRewards_mainTitle_TurnYourStileInRewards}</h2>
        <p>{translations.landingPage_sectionRewards_mainTitle_description}</p>
        <a href={`https://style.matchthelook.com/${language}`}><button>{translations.landingPage_sectionRewards_cta_button_discoverMore}</button></a>
      </div>
    </section>
  );
}

export default SECTION_REWARDS;
