import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import SECTION_HERO from '../../pages/public/landingPage/Section_Hero'
import SECTION_MURAL from '../../pages/public/landingPage/Section_Mural'
import SECTION_REWARDS from '../../pages/public/landingPage/Section_Rewards'
import SECTION_AI from '../../pages/public/landingPage/Section_AI'
import SECTION_CONTACTS from '../../pages/public/landingPage/Section_Contacts'
import SECTION_FOOTER from '../../pages/public/landingPage/Section_Footer'
import '../../style/common.css';

const HOOKERLANDINGPAGE = () => {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <>
            <section className='common-public-wrapper'>
                <Helmet>
                    <title>{translations.seo_landingPage_title}</title>
                    <meta name="description" content={translations.seo_landingPage_description} />
                    <meta name="keywords" content="fashion, style, outfit inspiration, AI fashion advisor, personal stylist, fashion SaaS, wardrobe inspiration, clothing recommendations, moda, estilo, inspiração de looks, consultoria de moda com IA, estilista pessoal, inspiração de guarda-roupa, recomendações de roupas, buy clothes, outfit ideas, fashion tips, style improvement, clothing brands, where to buy outfits, inspiration for dressing well, shopping fashion online, affordable fashion, trendy clothing, shop the look, fashion advice, best fashion trends, seasonal outfits, outfit matching, AI style suggestions, personal shopper online, latest fashion trends, fashion influencers, fashion community, style boards, personalized styling, shop outfits online, unique fashion finds, dress better tips, comprar roupa, ideias de looks, dicas de moda, melhorar o estilo, marcas de roupa, onde comprar roupa, inspiração para vestir bem, compras de moda online, moda acessível, roupas da moda, inspiração de looks, melhores tendências de moda, looks sazonais, combinação de outfits, sugestões de estilo IA, personal shopper online, últimas tendências de moda, influenciadores de moda, comunidade de moda, painéis de estilo, estilo personalizado, comprar outfits online, moda única, dicas para vestir melhor" />
                    
                    {/* Open Graph tags for social sharing */}
                    <meta property="og:title" content={translations.seo_landingPage_orgTitle} />
                    <meta property="og:description" content={translations.seo_landingPage_orgDescription} />
                    <meta property="og:image" content="[URL_TO_HERO_IMAGE]" />
                    <meta property="og:url" content="https://matchthelook.com" />
                    <meta property="og:type" content="website" />

                    {/* Twitter Card metadata */}
                    <meta name="twitter:card" content="/logo.png" />
                    <meta name="twitter:title" content={translations.seo_landingPage_twitterTitle} />
                    <meta name="twitter:description" content={translations.seo_landingPage_twitterDescription} />
                    <meta name="twitter:image" content="/logo.png" />

                    {/* Language settings for bilingual audience */}
                    <link rel="alternate" href="https://matchthelook.com" hreflang="en" />
                    <link rel="alternate" href="https://matchthelook.com/pt" hreflang="pt" />
                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>
                <SECTION_HERO />
                <Element name="mural" className='common-public-element'><SECTION_MURAL /></Element>
                <Element name="style" className='common-public-element'><SECTION_REWARDS /></Element>
                <Element name="ai" className='common-public-element'><SECTION_AI /></Element>
                <Element name="contacts" className='common-public-element'><SECTION_CONTACTS /></Element>
                <SECTION_FOOTER />
            </section>
        </>
    );
};

export default HOOKERLANDINGPAGE;
