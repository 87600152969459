import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Hero.css';

function SECTION_HERO() {
  const location = useLocation();
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredUsername } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <section className='section-hero-wrapper'>
      <div className='section-hero-menu-container'>
        <div className='section-hero-menu-container-wrapper'>
          <div className='section-hero-menu-company'>
            <h1 translate='no'>Match the Look</h1>
          </div>
          <div className='section-hero-menu-options'>
            <ScrollLink to="mural" smooth={true} duration={500}>
              <Link to={`/${language}/#mural`} className="menu-link">{translations.landingPage_sectionHero_menuMural}</Link>
            </ScrollLink>

            <ScrollLink to="style" smooth={true} duration={500}>
              <Link to={`/${language}/#style`} className="menu-link">{translations.landingPage_sectionHero_menuRewards}</Link>
            </ScrollLink>

            <ScrollLink to="ai" smooth={true} duration={500}>
              <Link to={`/${language}/#ai`} className="menu-link">{translations.landingPage_sectionHero_menuAI}</Link>
            </ScrollLink>

            <ScrollLink to="contacts" smooth={true} duration={500}>
              <Link to={`/${language}/#contacts`} className="menu-link">{translations.landingPage_sectionHero_menuContacts}</Link>
            </ScrollLink>
          </div>
          <div className='section-hero-menu-cta'>
            {isAuthenticated ? (
              <a href={`/${language}/account/${localStoredUsername}/mural`}><button>{translations.landingPage_sectionHero_CTA_authUser_exploreMural}</button></a>
            ) : (
              <>
                <a href={`/${language}/login`}>{translations.landingPage_sectionHero_CTA_nonAuthUser_loginNow}</a>
                <a href={`/${language}/register`}><button>{translations.landingPage_sectionHero_CTA_nonAuthUser_registerNow}</button></a>
              </>
            )}
          </div>
        </div>
      </div>

      <div className='section-hero-main-title-wrapper'>
        <div className='section-hero-main-title-wrapper-container'>
          <h1>{translations.landingPage_sectionHero_mainTitle_inspireShareShop}</h1>
          <p>{translations.landingPage_sectionHero_mainTitle_description}</p>

          <div className='section-hero-main-title-wrapper-container-arrow'>
            <img src='/landingPage/down-arrow.png' alt='' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SECTION_HERO;
