import React, { useState, useContext, useRef, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import UPGRADEPLUSPROMPT from '../../components/upgradePlusPrompt';
import '../../style/private/ai.css';
import '../../style/private/ai/ai_images.css';
import '../../style/private/ai/ai_upload.css';

function AI() {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const { localStoredGender, localStoredEmail, localStoredUsername, localStoredCountry, localStoredSession, localStoredBirthday } = getSessionInfo();

    const textareaRef = useRef(null);

    const [promptSubmited, setPromptSubmited] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [typingMessage, setTypingMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [conversationID, setConversationID] = useState('');
    const [lastAIResponse, setLastAIResponse] = useState('');
    const messagesEndRef = useRef(null);
    const [exchangeNumber, setExchangeNumber] = useState(0);

    const [imageLoading, setImageLoading] = useState(false);
    const [generatedImages, setGeneratedImages] = useState([]);
    const [upradePlusPromptVisible, setUpgradePlusPromptVisible] = useState(false);

    const [userHasUploadedImage, setUserHasUploadedImage] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);
    const [blobFile, setBlobFile] = useState('');

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);

        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const [submitTrigger, setSubmitTrigger] = useState(false);
    const setPrompt1 = () => {
        setPrompt(translations.private_ai_prompt_example_01);
        setSubmitTrigger(true);
    };
    const setPrompt2 = () => {
        setPrompt(translations.private_ai_prompt_example_02);
        setSubmitTrigger(true);
    };
    const setPrompt3 = () => {
        setPrompt(translations.private_ai_prompt_example_03);
        setSubmitTrigger(true);
    };

    useEffect(() => {
        if (submitTrigger) {
            handleSubmit();
            setSubmitTrigger(false); // Reset trigger after submission
        }
    }, [prompt, submitTrigger]);

    const handleSubmit = () => {
        if (userHasUploadedImage) {
            submitToAnalyzeImages();
            return;
        }

        const textarea = document.querySelector('.input-ai-wrapper textarea');
        textarea.style.height = 'auto';

        setPromptSubmited(true);
        setIsTyping(true);
        setExchangeNumber(exchangeNumber + 1);

        if (prompt.trim()) {

            setLoading(true);
            setMessages(prevMessages => [...prevMessages, { type: 'user', text: prompt }]);
            setPrompt('');

            const baseURL = `${MainContext.apiURL}/api/v1/private/users/chat/ai`;
            const formData = new URLSearchParams({
                conversation: conversationID,
                country: localStoredCountry,
                gender: localStoredGender,
                messages: JSON.stringify(messages),
                prompt: prompt,
            });

            fetch(baseURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession
                },
                credentials: 'include',
                body: formData.toString(),
            })
                .then(response => {
                    if (!response.ok) {
                        setLoading(false);
                        simulateTyping(translations.private_ai_interaction_error);
                        setPromptSubmited(false);
                        return Promise.reject({ status: response.status, statusText: response.statusText });
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    setLoading(false);

                    if (data.response !== lastAIResponse) {
                        setLastAIResponse(data.response);
                        simulateTyping(data.response);
                    }

                    if (!conversationID) {
                        setConversationID(data.conversation);
                    }

                    setPromptSubmited(false);
                })
                .catch(error => {
                    setPromptSubmited(false);
                });
        }
    };

    const simulateTyping = (text) => {
        let index = 0;
        setTypingMessage('');
        setMessages(prevMessages => [...prevMessages, { type: 'ai', text: '' }]);

        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        const typeInterval = setInterval(() => {
            if (index < text.length) {
                setTypingMessage(prev => prev + text.charAt(index));
                index++;
            } else {
                clearInterval(typeInterval);
                setIsTyping(false);

                setMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1] = { type: 'ai', text: text };
                    return newMessages;
                });
            }

            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1);
    };

    const formatText = (text) => {
        const formattedText = text
            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
            .replace(/### (.*?)\n/g, '<h3>$1</h3>\n')
            .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');
        return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    useEffect(() => {
        if (typingMessage && messages.length > 0) {
            const lastMessageIndex = messages.length - 1;
            setMessages(prevMessages => {
                if (prevMessages[lastMessageIndex].text !== typingMessage) {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[lastMessageIndex].text = typingMessage;
                    return updatedMessages;
                }
                return prevMessages;
            });
        }
    }, [typingMessage]);

    useEffect(() => {
        const aiMessages = messages.filter(message => message.type === 'ai');
        if (aiMessages.length > 0) {
            setLastAIResponse(aiMessages[aiMessages.length - 1].text);
        }
    }, [messages]);

    useEffect(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;

            const resizeTextarea = () => {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            };

            textarea.addEventListener('input', resizeTextarea);

            return () => {
                textarea.removeEventListener('input', resizeTextarea);
            };
        }
    }, []);

    const generatesImage = (index) => {

        setImageLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/generate-image/ai`;

        const formData = new URLSearchParams({
            country: localStoredCountry,
            gender: localStoredGender,
            prompt: prompt,
            response: lastAIResponse,
            conversationID: conversationID,
            birthday: localStoredBirthday,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setImageLoading(false);
                    simulateTyping(translations.private_ai_interaction_error);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setImageLoading(false);

                const image = `${data.image}${data.extension}`;

                setGeneratedImages((prevImages) => {
                    const newImages = [...prevImages];
                    newImages[index] = image;
                    return newImages;
                });
            })
            .catch(error => {
                setImageLoading(false);
            });
    };

    /* -------------------- UPLOADS CUSTOM IMAGE -------------------- */

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        setUserHasUploadedImage(true);
        const file = event.target.files[0];

        if (file) {
            const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(file.type)) {
                alert("Please upload a JPEG, PNG, or JPG image.");
                setUserHasUploadedImage(false);
                return;
            }

            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);

            const blob = new Blob([file], { type: file.type });
            setBlobFile(blob);
        }
    };

    const submitToAnalyzeImages = () => {
        const textarea = document.querySelector('.input-ai-wrapper textarea');
        textarea.style.height = 'auto';

        setPromptSubmited(true);
        setIsTyping(true);
        setExchangeNumber(exchangeNumber + 1);

        if (prompt === '') {
            setMessages(prevMessages => [...prevMessages, { type: 'user', text: null }]);
        } else {
            setMessages(prevMessages => [...prevMessages, { type: 'user', text: prompt }]);
        }

        const formData = new FormData();
        formData.append('country', localStoredCountry);
        formData.append('gender', localStoredGender);

        if (prompt === '') {
            formData.append('prompt', 'Analyze this image and provide me the references on where to buy a similar outfit.');
        } else {
            formData.append('prompt', prompt);
        }

        setLoading(true);
        setPrompt('');

        if (blobFile) {
            formData.append('image', blobFile);
        } else {
            console.error('No image file selected');
            setLoading(false);
            simulateTyping(translations.private_ai_interaction_error);
            return;
        }

        // Make the API request
        fetch(`${MainContext.apiURL}/api/v1/private/users/analyze-image/ai`, {
            method: 'POST',
            headers: {
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    simulateTyping(translations.private_ai_interaction_error);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);

                // Use the AI's response for recommendations
                const aiResponse = data.recommendations;
                simulateTyping(aiResponse);
                setPromptSubmited(false);
            })
            .catch(error => {
                setLoading(false);
                simulateTyping(translations.private_ai_interaction_error);
                setPromptSubmited(false);
            });
    };

    return (
        <section className={messages.length === 0 ? ('page-ai-wrapper') : ('page-ai-wrapper-after')}>

            <div className='page-ai-content'>
                <h2>{translations.private_ai_title}</h2>

                {messages.length === 0 && (
                    <>
                        <div className='page-ai-prompt'>

                            {userHasUploadedImage && (
                                <div className="image-ai-preview-container" onClick={handleSubmit}>
                                    <img src={imagePreview} alt="Uploaded Preview" />
                                    <div className='image-ai-preview-container-overlay'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M17.5 17.5L22 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
                                            <path d="M9.4924 7.5C8.77591 7.54342 8.31993 7.66286 7.99139 7.99139C7.66286 8.31993 7.54342 8.77591 7.5 9.4924M12.5076 7.5C13.2241 7.54342 13.6801 7.66286 14.0086 7.99139C14.3371 8.31993 14.4566 8.77591 14.5 9.4924M14.4923 12.6214C14.4431 13.273 14.3194 13.6978 14.0086 14.0086C13.6801 14.3371 13.2241 14.4566 12.5076 14.5M9.4924 14.5C8.7759 14.4566 8.31993 14.3371 7.99139 14.0086C7.6806 13.6978 7.55693 13.273 7.50772 12.6214" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>{translations.private_ai_uploadAndAnalyze}</span>
                                    </div>
                                </div>
                            )}

                            <div className="input-ai-wrapper">
                                <textarea placeholder={!userHasUploadedImage ? (translations.private_ai_placeholder_01) : (translations.private_ai_placeholder_02)} value={prompt} onChange={handlePromptChange} disabled={promptSubmited} onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) { e.preventDefault(); handleSubmit(); } }} />
                                <button className="input-ai-icon-button-upload" onClick={handleButtonClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                        <path d="M13 3.00231C12.5299 3 12.0307 3 11.5 3C7.02166 3 4.78249 3 3.39124 4.39124C2 5.78249 2 8.02166 2 12.5C2 16.9783 2 19.2175 3.39124 20.6088C4.78249 22 7.02166 22 11.5 22C15.9783 22 18.2175 22 19.6088 20.6088C20.9472 19.2703 20.998 17.147 20.9999 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                        <path d="M2 14.1354C2.61902 14.0455 3.24484 14.0011 3.87171 14.0027C6.52365 13.9466 9.11064 14.7729 11.1711 16.3342C13.082 17.7821 14.4247 19.7749 15 22" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                        <path d="M21 16.8962C19.8246 16.3009 18.6088 15.9988 17.3862 16.0001C15.5345 15.9928 13.7015 16.6733 12 18" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                        <path d="M17 4.5C17.4915 3.9943 18.7998 2 19.5 2M22 4.5C21.5085 3.9943 20.2002 2 19.5 2M19.5 2V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} />
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} />
                                <button className="input-ai-icon-button" onClick={handleSubmit}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                        <path d="M16 13.5C16 13.5 13.054 10.5 12 10.5C10.9459 10.5 8 13.5 8 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>

                            <div className='input-ai-examples-prompts'>
                                <button onClick={setPrompt1}>{translations.private_ai_prompt_example_01}</button>
                                <button onClick={setPrompt2}>{translations.private_ai_prompt_example_02}</button>
                                <button onClick={setPrompt3}>{translations.private_ai_prompt_example_03}</button>
                            </div>
                        </div>
                    </>
                )}

                {messages.length > 0 && (
                    <>
                        <div className='page-ai-interaction-wrapper'>


                            {userHasUploadedImage && (
                                <div className="image-ai-preview-container">
                                    <img src={imagePreview} alt="Uploaded Preview" className="image-preview" />
                                </div>
                            )}

                            <div className='page-ai-interaction-messages'>
                                {messages.map((message, index) => (
                                    <>
                                        {message.text && (
                                            <div key={index} className={`message-bubble ${message.type === 'user' ? 'user-message' : 'ai-message'}`}>
                                                {message.type === 'ai' ? formatText(message.text) : <span>{message.text}</span>}
                                            </div>
                                        )}

                                        {message.type === 'ai' && generatedImages[index] && (
                                            <div className='page-ai-interaction-message-picture-generated'>
                                                <img src={`${MainContext.generatedImages}${generatedImages[index]}`} alt='Generated with Artificial Intelligence' />
                                            </div>
                                        )}

                                        {/* Generate Image button, only for AI messages */}
                                        {message.type === 'ai' && !loading && !imageLoading && !isTyping && !generatedImages[index] &&
                                            index === messages.map((m, i) => m.type === 'ai' ? i : null).filter(i => i !== null).pop() && !userHasUploadedImage && (
                                                <div className='page-ai-interaction-message-button-generate-image'>
                                                    <button onClick={() => generatesImage(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                                            <circle cx="7" cy="8" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M20.9977 11C21 11.4701 21 11.9693 21 12.5C21 16.9783 21 19.2175 19.6088 20.6088C18.2175 22 15.9783 22 11.5 22C7.02166 22 4.78249 22 3.39124 20.6088C2 19.2175 2 16.9783 2 12.5C2 8.02166 2 5.78249 3.39124 4.39124C4.78249 3 7.02166 3 11.5 3C12.0307 3 12.5299 3 13 3.00231" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                            <path d="M18.5 2L18.7579 2.69703C19.0961 3.61102 19.2652 4.06802 19.5986 4.40139C19.932 4.73477 20.389 4.90387 21.303 5.24208L22 5.5L21.303 5.75792C20.389 6.09613 19.932 6.26524 19.5986 6.59861C19.2652 6.93198 19.0961 7.38898 18.7579 8.30297L18.5 9L18.2421 8.30297C17.9039 7.38898 17.7348 6.93198 17.4014 6.59861C17.068 6.26524 16.611 6.09613 15.697 5.75792L15 5.5L15.697 5.24208C16.611 4.90387 17.068 4.73477 17.4014 4.40139C17.7348 4.06802 17.9039 3.61102 18.2421 2.69703L18.5 2Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                                            <path d="M4.5 21.5C8.87246 16.275 13.7741 9.38406 20.9975 14.0424" stroke="currentColor" stroke-width="1.5" />
                                                        </svg>
                                                        {translations.private_ai_generateImages}
                                                    </button>
                                                </div>
                                            )}

                                    </>
                                ))}

                                {loading && (
                                    <>
                                        <div className='page-ai-interaction-loader-wrapper'>
                                            <span className="page-ai-interaction-loader01"></span>
                                        </div>
                                    </>
                                )}

                                {imageLoading && (
                                    <>
                                        <div className='page-ai-interaction-message-picture'>
                                            <span class="page-ai-interaction-message-picture-loader"></span>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className='page-ai-prompt'>
                                <div className="input-ai-wrapper">
                                    <textarea placeholder='Diz-me em que posso ajudar' value={prompt} onChange={handlePromptChange} disabled={isTyping} onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) { e.preventDefault(); handleSubmit(); } }} />

                                    <button className="input-ai-icon-button" onClick={handleSubmit}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M16 13.5C16 13.5 13.054 10.5 12 10.5C10.9459 10.5 8 13.5 8 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </>
                )}

            </div>

            {upradePlusPromptVisible && (
                <UPGRADEPLUSPROMPT
                    actionOnClick={setUpgradePlusPromptVisible}
                />
            )}

        </section>
    );
}

export default AI;