import { useState, useEffect, useRef, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP5({ setRegisterStep, userPlan, email, password, displayName, usernameChoosen, referralCode, generateSecureLinkForPayment, securityToken }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCountryList();
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const getCountryList = () => {
    const data = new URLSearchParams({
      language: language
    });

    fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          return response.json();
        }
      })
      .then(data => {
        setCountryOptions(data.countries);
      })
      .catch(error => {

      });
  };

  const [loading, setLoading] = useState(false);
  const [t_firstName, t_setFirstName] = useState('');
  const [t_lastName, t_setLastName] = useState('');
  const [t_gender, t_setGender] = useState('');
  const [t_country, t_setCountry] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [t_birthday, t_setBirthday] = useState('');
  const previousValueRef = useRef('');

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangeBirthday = (e) => {
    const inputValue = e.target.value;
    setBirthdayError(false);

    const formattedDate = formatDate(inputValue);
    const [day, month, year] = formattedDate.split('-').map(Number);

    const isFourDigitYear = year && year.toString().length === 4;

    t_setBirthday(formattedDate);
    previousValueRef.current = formattedDate;

    const isValidDate =
      (day >= 1 && day <= 31) &&
      (month >= 1 && month <= 12) &&
      isFourDigitYear;

    if (!isValidDate) {
    }
  };

  const formatDate = (value) => {
    let cleaned = value.replace(/\D/g, '');

    if (cleaned.length >= 3 && cleaned.length <= 4) {
      cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2);
    } else if (cleaned.length >= 5) {
      cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2, 4) + '-' + cleaned.slice(4);
    }

    return cleaned.slice(0, 10); // Limit to DD-MM-YYYY format
  };

  const createAccount = () => {
    if (t_firstName === '' || t_lastName === '' || t_gender === '' || t_birthday === '' || t_country === '') {
      if (t_firstName === '') {
        setFirstNameError(true);
      }
      if (t_lastName === '') {
        setLastNameError(true);
      }
      if (t_gender === '') {
        setGenderError(true);
      }
      if (t_birthday === '') {
        setBirthdayError(true);
      }
      if (t_country === '') {
        setCountryError(true);
      }

      setErrorMessage(translations.auth_register_errorMessage);
      return;
    }

    setFirstNameError(false);
    setLastNameError(false);
    setGenderError(false);
    setBirthdayError(false);
    setCountryError(false);

    setErrorMessage('');
    setLoading(true);

    createUserAccount();
  }

  const createUserAccount = () => {
    const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/create-account`;

    const formData = new URLSearchParams({
      email: email.toLowerCase(),
      password: password,
      username: usernameChoosen,
      name: t_firstName,
      surname: t_lastName,
      birthday: t_birthday,
      gender: t_gender,
      country: t_country,
      language: language,
      referralCode: referralCode,
      displayName: displayName,
      securityToken: securityToken
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorMessage(translations.auth_login_errorGenericMessage);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setRegisterStep(7);
          return response.json();
        }
      })
      .then(data => {
      })
      .catch(error => {
        setErrorMessage(translations.auth_login_errorGenericMessage);
        setLoading(false);
      });
  };

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_register_mainTitle}</h2>
      </div>

      <div className='auth-register-wrapper-form'>
        <div className='auth-register-form-row-2'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_register_firstNameLabel}</span>
            <input type='text' placeholder={translations.auth_register_firstNamePlaceholder} value={t_firstName} onChange={(e) => { t_setFirstName(e.target.value); setFirstNameError(false); setErrorMessage(''); }} className={firstNameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
          <div className='auth-register-form-input'>
            <span>{translations.auth_register_lastNameLabel}</span>
            <input type='text' placeholder={translations.auth_register_lastNamePlaceholder} value={t_lastName} onChange={(e) => { t_setLastName(e.target.value); setLastNameError(false); setErrorMessage(''); }} className={lastNameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className="auth-register-form-input">
            <span>{translations.auth_register_genderLabel}</span>
            <select value={t_gender} onChange={(e) => { t_setGender(e.target.value); setGenderError(false); setErrorMessage(''); }} className={genderError ? ('auth-register-form-input-select-error') : ('auth-register-form-input-select')}>
              <option value="" disabled> {translations.auth_register_genderPlaceholder} </option>
              <option value="man">{translations.auth_register_genderMale}</option>
              <option value="woman">{translations.auth_register_genderFemale}</option>
            </select>
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_register_birthdayLabel}</span>
            <input type='text' placeholder='dd-mm-yyyy' value={t_birthday} onChange={handleChangeBirthday} pattern='(?:0[1-9]|[12][0-9]|3[01])-(?:0[1-9]|1[0-2])-(?:19|20)[0-9]{2}' className={birthdayError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className="auth-register-form-input">
            <span>{translations.auth_register_countryLabel}</span>
            <select value={t_country} onChange={(e) => { t_setCountry(e.target.value); setCountryError(false); setErrorMessage(''); }} className={countryError ? ('auth-register-form-input-select-error') : ('auth-register-form-input-select')}>
              <option value='' disabled selected>{translations.auth_register_countryPlaceholder}</option>
              {countryOptions.map((nationality, index) => (
                <option key={index} value={nationality.value}>
                  {nationality.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-register-button'>
        <LoadingButton
          buttonText={translations.auth_register_createrAccount}
          loading={loading}
          actionOnClick={createAccount}
        />
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP5;
